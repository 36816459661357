"use client";

import Icon from "@/components/Icon/Icon";
import useBreakpoint from "@/hooks/useBreakpoint";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import styles from "./Button.module.scss";

const payIcons = [
  "Visa",
  "Mastercard",
  // "ApplePay",
  // "GooglePay",
];

export interface IButton {
  variant?:
    | "small"
    | "text"
    | "icon-white"
    | "blue"
    | "white"
    | "white-small"
    | "white-tiny";
  isCenteredText?: boolean;
  label?: string | undefined;
  sublabel?: string;
  onClick?: (e?: any) => any | undefined;
  linkTo?: string;
  icon?: string;
  isDisabled?: boolean;
  isScrollToTop?: boolean;
  isPayButton?: boolean;
  target?: "_self" | "_blank";
}

const Button: React.FC<IButton> = ({
  variant,
  label,
  sublabel,
  onClick,
  linkTo = undefined,
  icon,
  isCenteredText = false,
  isDisabled,
  isScrollToTop,
  isPayButton,
  target = "_self",
}) => {
  const variantClassName = variant ? styles[variant] : styles.base;
  const BreakpointXXS = useBreakpoint("xxs");
  const handleClick = () => {
    if (onClick && !isDisabled) {
      onClick();
    }
    if (isScrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const getIcon = (icon) => {
    let iconUrl = undefined;
    try {
      iconUrl = require(`../../assets/payIcons/${icon}.svg`);
    } catch (error) {
      console.error(error);
    }

    return iconUrl;
  };

  const getLabel = (
    <div className={styles.labelWrapper}>
      {icon && (
        <div
          className={classNames(styles.icon, {
            [styles.noMargin]: !label,
          })}
        >
          <Icon icon={icon} />
        </div>
      )}
      {label && (
        <div
          className={classNames(styles.label, {
            [styles.centeredText]: isCenteredText,
          })}
        >
          {label}
          {sublabel && <span className={styles.sublabel}>{sublabel}</span>}
        </div>
      )}
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <Link
        className={classNames(
          styles.button,
          { [variantClassName]: variant },
          { [styles.disabled]: isDisabled }
        )}
        href={isDisabled || !linkTo ? "#" : linkTo}
        onClick={() => handleClick()}
        target={target}
      >
        {getLabel}
        {isPayButton && (
          <div className={styles.payButton}>
            {!BreakpointXXS && <span>Direkt bezahlen mit</span>}
            {payIcons.map((icon) => (
              <Image key={icon} src={getIcon(icon)} alt={icon} />
            ))}
          </div>
        )}
      </Link>
    </div>
  );
};

export default Button;
