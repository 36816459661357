"use client";

import Button from "@/components/Button/Button";
import { IOverlay } from "@/components/Overlay/Overlay";
import OverlayContext from "@/context/OverlayContext";
import { useRouter, useSearchParams } from "next/navigation";
import { useContext, useState } from "react";
import { IMaskInput } from "react-imask";
import styles from "./SearchModule.module.scss";

const SearchModule = ({}) => {
  const [id, setId] = useState("");
  const [error, setError] = useState("");

  const searchParams = useSearchParams();
  const urlId = searchParams.get("id");
  const router = useRouter();
  const {
    setId: setOverlayId,
    setShow,
    setContent,
  } = useContext<IOverlay>(OverlayContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!String(id).includes("_")) {
      router.push("/" + id);
    } else {
      setError("Bitte die gesamte Ladepunkt-ID angeben");
    }
  };

  const handleOverlay = async (e: any) => {
    e?.preventDefault();
    id === "" ? setOverlayId("DE*ENC*ELA001001*001*A") : setOverlayId(id);
    setContent({
      text: "Die Ladepunkt-ID mit 18 Ziffern finden Sie als Aufkleber an der Ladestation.",
    });
    setShow(true);
  };

  return (
    <div className={styles.searchModule}>
      <div className={styles.inputWrapper}>
        <form className={styles.form} onSubmit={(e) => handleSubmit(e)}>
          <IMaskInput
            className={styles.input}
            mask={"DE\\*ENC\\*ELA000000\\*###\\*a"}
            definitions={{ "#": /[0-9a-zA-Z]/ }}
            value={id || urlId}
            unmask={false}
            lazy={false}
            onAccept={(value) => {
              setId(value.toUpperCase());
            }}
          />
          <div className={styles.searchButton}>
            <Button
              icon="search"
              isDisabled={id === "" || String(id).includes("_")}
              linkTo={"/" + id}
            />
          </div>
        </form>
      </div>
      {(error || urlId) && (
        <div className={styles.error}>
          {error || "Die Ladepunkt-ID existiert nicht."}
        </div>
      )}
      <div className={styles.info}>
        <Button
          onClick={(e) => handleOverlay(e)}
          label="Wo finde ich die Ladepunkt-ID?"
          variant="text"
          isScrollToTop={false}
        />
      </div>
      <div className={styles.teaser}>
        <p>
          Öffentliche Ladestationen
          <br />
          <span>Über 400.000 in ganz Europa</span>
        </p>
        <div className={styles.button}>
          <Button
            linkTo="https://www.enercity.de/privatkunden/produkte/elektromobilitaet/oeffentliches-laden/karte"
            label="Karte öffnen"
            variant="blue"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchModule;
