"use client";

import Animation from "@/components/Animation/Animation";
import Button from "@/components/Button/Button";
import Image from "next/image";
import styles from "./PolyComponent.module.scss";
import useBreakpoint from "@/hooks/useBreakpoint";

const PolyComponent = ({
  title,
  text,
  riveUrl,
  linkTo,
  label,
  image,
  spacer,
  isResponsive,
}: {
  title?: string;
  text?: string;
  riveUrl?: string;
  linkTo?: string;
  label?: string;
  image: any;
  spacer?: number;
  isResponsive?: boolean;
}) => {
  const BreakpointS = useBreakpoint("s");
  return (
    <div className={styles.polyComponent}>
      <div className={styles.wrapper}>
        {image && (
          <div className={styles.bg}>
            <Image src={image} alt="Polygon" />
          </div>
        )}
        {riveUrl && (
          <div
            className={styles.animation}
            style={spacer ? { marginBottom: spacer + "rem" } : {}}
          >
            <Animation
              riveUrl={riveUrl}
              artboard={
                isResponsive ? (BreakpointS ? "Mobile" : "Desktop") : null
              }
            />
          </div>
        )}
        <div className={styles.text}>
          <h2>{title}</h2>
          <p>{text}</p>
        </div>
      </div>
      {linkTo && (
        <div className={styles.button}>
          <Button linkTo={linkTo} label={label} variant="white-small" />
        </div>
      )}
    </div>
  );
};
export default PolyComponent;
