"use client";

import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";

import classNames from "classnames";
import styles from "./Animation.module.scss";

const Animation = ({
  riveUrl,
  artboard,
  dismantleDuration = 300,
  prevArtboard,
}: {
  riveUrl?: string;
  artboard?: string;
  dismantleDuration?: number;
  prevArtboard?: boolean;
}) => {
  const { RiveComponent } = useRive({
    src: riveUrl,
    layout: new Layout({ fit: Fit.Contain, alignment: Alignment.Center }),
    artboard: artboard || "Artboard",
    stateMachines: "State Machine",
    autoplay: true,
  });

  return (
    <div
      className={classNames(styles.animation, { [styles.prev]: prevArtboard })}
      style={
        {
          "--dismantle-duration": `${dismantleDuration}s`,
        } as React.CSSProperties
      }
    >
      <RiveComponent />
    </div>
  );
};
export default Animation;
