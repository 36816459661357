"use client";

import Image from "next/image";
import { useRouter } from "next/navigation";

import logo from "@/assets/Logo.svg";
import Icon from "@/components/Icon/Icon";
import styles from "./HeaderBar.module.scss";

const HeaderBar = ({ hasBackArrow = false }) => {
  const router = useRouter();

  return (
    <div className={styles.wrapper}>
      {hasBackArrow && (
        <a
          className={styles.arrow}
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            router.back();
          }}
        >
          <Icon icon="arrow-left-violet" />
        </a>
      )}
      <div className={styles.logo}>
        <Image src={logo} alt="enercity_logo" />
      </div>
    </div>
  );
};

export default HeaderBar;
